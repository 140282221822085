import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => {
  return (
    <footer className="py-10">
      <div className="container mx-auto px-8">
        <div className="flex flex-col">
          <span className="text-xs text-vividBlue font-bold mb-2">Contact</span>
          <ul className="flex mb-8 text-xs">
            <li className="mr-2">
              <a className="hover:underline" href="mailto:mato.njavro@zsem.hr">
                Mato Njavro,
              </a>
            </li>
            <li className="mr-2">
              <a className="hover:underline" href="mailto:zstopic@zsem.hr">
                Zvonimir Stopić,
              </a>
            </li>
            <li>
              <a className="hover:underline" href="mailto:bakotaivic@gmail.com">
                Ivica Bakota
              </a>
            </li>
          </ul>
        </div>
        <div className="flex items-center">
          <StaticImage
            className="w-36 mr-12"
            src="../../images/zsem-logo.png"
            alt="ZSEM"
            placeholder="hidden"
            quality="100"
          />
          <StaticImage
            className="w-52"
            src="../../images/croasia-logo.png"
            alt="Croasia logo"
            placeholder="hidden"
            quality="100"
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
