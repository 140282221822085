import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'

import { Link, navigate } from 'gatsby'

import useOutsideClick from 'hooks/useOutsideClickHook'
import Logo from 'images/croasia-logo.png'

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)
  const ref = useRef()

  const handleToggleMenu = () => {
    setToggleMenu((toggleMenu) => !toggleMenu)
  }

  useOutsideClick(ref, () => {
    if (toggleMenu) setToggleMenu(false)
  })

  const handleCloseMenuOnLinkClick = () => setToggleMenu(false)

  return (
    <header className="py-4">
      <Headroom>
        <div className="container mx-auto px-8">
          <div className="flex justify-between items-center">
            <svg
              onClick={handleToggleMenu}
              className="w-8 cursor-pointer transform hover:scale-95 transition-transform duration-200 ease-in-out"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>

            <a href="/">
              <img className="w-40" src={Logo} alt="Croasia logo" />
            </a>
          </div>
        </div>
      </Headroom>
      <div
        ref={ref}
        className={`fixed top-0 bottom-0 z-10 h-screen w-64 bg-vividBlue opacity-95 transform transition-transform ease-in-out duration-500 ${
          toggleMenu ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex items-center justify-between px-7 py-10">
          <Link to="/" onClick={handleCloseMenuOnLinkClick}>
            <svg className="w-8 cursor-pointer" width="20" height="23" viewBox="0 0 20 23" fill="none">
              <path
                d="M1 8.22803L10 1.22803L19 8.22803V19.228C19 19.7585 18.7893 20.2672 18.4142 20.6422C18.0391 21.0173 17.5304 21.228 17 21.228H3C2.46957 21.228 1.96086 21.0173 1.58579 20.6422C1.21071 20.2672 1 19.7585 1 19.228V8.22803Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 21.228V11.228H13V21.228"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          <svg
            onClick={handleToggleMenu}
            className="w-8 cursor-pointer transform hover:scale-95 transition-transform duration-200 ease-in-out"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <nav>
          <ul className="px-7 mt-10">
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/about"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                About CAI
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/leadership"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Leadership
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/research"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Research
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/publication-and-publishing"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Publication and Publishing
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/services"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Services
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/news"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                News
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/partners"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Partners
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/media"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                In the Media and Events
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/database"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Database
              </Link>
            </li>
            <li className="mb-5">
              <Link
                onClick={handleCloseMenuOnLinkClick}
                className="text-white text-sm hover:underline"
                to="/contact"
                activeClassName="text-yellow-300 hover:no-underline"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {}

export default Header
