// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-database-js": () => import("./../../../src/pages/database.js" /* webpackChunkName: "component---src-pages-database-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-publication-and-publishing-js": () => import("./../../../src/pages/publication-and-publishing.js" /* webpackChunkName: "component---src-pages-publication-and-publishing-js" */),
  "component---src-pages-publishing-js": () => import("./../../../src/pages/publishing.js" /* webpackChunkName: "component---src-pages-publishing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-media-js": () => import("./../../../src/templates/media.js" /* webpackChunkName: "component---src-templates-media-js" */),
  "component---src-templates-media-post-js": () => import("./../../../src/templates/media-post.js" /* webpackChunkName: "component---src-templates-media-post-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-research-js": () => import("./../../../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-research-post-js": () => import("./../../../src/templates/research-post.js" /* webpackChunkName: "component---src-templates-research-post-js" */)
}

