import React from 'react'
import PropTypes from 'prop-types'

import { useStaticQuery, graphql } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'

import Header from 'components/Header'
import Footer from 'components/Footer'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const removeMainBg = () => {
    const research = location.pathname.split('/')[2] ? `/research/${location.pathname.split('/')[2]}` : ''
    const news = location.pathname.split('/')[2] ? `/news/${location.pathname.split('/')[2]}` : ''
    const media = location.pathname.split('/')[2] ? `/media/${location.pathname.split('/')[2]}` : ''

    switch (location.pathname) {
      case research:
        return true
        break
      case '/publishing':
        return true
        break
      case news:
        return true
        break
      case media:
        return true
        break

      default:
        return false
        break
    }
  }

  return (
    <>
      <SimpleReactLightbox>
        {/* <MainBackground /> */}
        <div
          className={`flex flex-col ${
            !removeMainBg() ? 'bg-main' : ''
          } min-h-screen bg-no-repeat bg-fixed z-0 bg-cover bg-top`}
        >
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className="flex flex-col flex-1">{children}</main>
          <Footer />
        </div>
      </SimpleReactLightbox>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
